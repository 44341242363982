import { render, staticRenderFns } from "./exchange-scan-product.vue?vue&type=template&id=35b31a50&scoped=true&"
import script from "./exchange-scan-product.vue?vue&type=script&lang=ts&"
export * from "./exchange-scan-product.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35b31a50",
  null
  
)

export default component.exports